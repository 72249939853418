<template>
  <v-form
    ref="form"
    class="district-form"
    v-model="valid"
    @submit.prevent="onSubmitForm"
  >
    <div class="form-body">
      <rcc-text-input
        label="Название"
        width="520px"
        v-model="form.name"
        :rules="[value => isRequiredField({ value: value.trim(), field: 'Название' })]"
      />
    </div>

    <rcc-confirm-dialog
      :is-show.sync="isShowConfirmDialog"
      title="Удалить округ?"
      text="Данное действие приведет к безвозвратному удалению федерального округа. Вы уверены?"
      :confirmCallback="handleDeleteDistrict"
    />

    <rcc-footer-buttons
      :danger-text="dangerButtonText"
      :is-submit="isSubmit"
      @danger-click="handleDeleteDistrictClick"
      @cancel-click="$router.push({ name: 'districts' })"/>
  </v-form>
</template>

<script>
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccTextInput from 'Components/controls/rcc-text-input'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccConfirmDialog
  },

  mixins: [Page, Form],

  data() {
    return {
      isShowConfirmDialog: false,
      form: {
        name: ''
      }
    }
  },

  computed: {
    dangerButtonText() {
      return ''
    }
  },

  methods: {
    handleDeleteDistrictClick() {
      this.isShowConfirmDialog = true
    },

    handleDeleteDistrict() {
      return
    }
  }
}
</script>

<style lang="scss" scoped>
.district-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  .form-body {
    flex-grow: 1;
    padding: $base-form-padding;
  }
}
</style>
